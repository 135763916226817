import React, { ReactElement } from 'react'
import { GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query'
import { TSearchPage } from '@mindfulchefuk/components/pages/SearchPage/SearchPage'
import { recipeSearchPageFilterDefaults } from '@mindfulchefuk/config/recipeSearchPageFilterDefaults'

import { Homepage } from '@mindfulchefuk/features/Homepage/Homepage'
import { isHomepageVariant } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'
import { getCMSPage } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { basePages } from '@mindfulchefuk/config/routesConfig'
import { getCMSGlobalContent } from '@mindfulchefuk/features/CMS/services/getCMSGlobalContent'
import {
  HomepageVariantPath,
  THomepage,
} from '@mindfulchefuk/features/Homepage/interfaces'
import { useDispatch } from 'react-redux'
import { setDeliveryDate } from '@mindfulchefuk/actions/basketActions'
import { DateDeliverability } from '@mindfulchefuk/features/Delivery/interfaces'
import { getDateDeliverabilities } from '@mindfulchefuk/features/Delivery/services/getDateDeliverabilities'
import { MC_POSTCODE } from '@mindfulchefuk/constants'
import { getImportantDates } from '@mindfulchefuk/features/Operations/utils/getImportantDates'
import { BBCGoodFoodHomepage } from '@mindfulchefuk/features/Homepage/BBCGoodFoodHomepage'
import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'

const SearchPage = dynamic<TSearchPage>(() =>
  import(
    /* webpackChunkName: "SearchPage" */ '@mindfulchefuk/components/pages/SearchPage/SearchPage'
  ).then((lib) => lib.SearchPage)
)

const BasePage = (props: THomepage): ReactElement => {
  const router = useRouter()
  const dispatch = useDispatch()

  const { basePage } = router.query as { basePage: string }
  const {
    heroImageDesktop,
    heroImageMobile,
    heroTrustpilotBannerDesktop,
    heroTrustpilotBannerMobile,
    heroRecipeBoxImage,
  } = props

  const recipeLibraryPage = recipeSearchPageFilterDefaults.baseUrl.find(
    ({ slug }) => slug === basePage
  )

  const { data }: { data: DateDeliverability[] } = useQuery([
    'dateDeliverabilities',
  ])

  const firstAvailableDeliveryDate = data?.find(
    (date: DateDeliverability) => date.recipeDeliverable
  )?.deliveryDate

  dispatch(setDeliveryDate(firstAvailableDeliveryDate))

  if (recipeLibraryPage) {
    return (
      <SearchPage
        slug={recipeLibraryPage.slug}
        filter={recipeLibraryPage.filter}
      />
    )
  }

  const voucherCode = isHomepageVariant(basePage as HomepageVariantPath)
    ? undefined
    : basePage

  const isBBCGoodFood = basePage === 'bbc-good-food' || basePage === 'good-food'
  const bbcGoodFoodVoucher =
    (router.query.savedVoucherCode as string) || 'GF25X4'

  if (
    isBBCGoodFood &&
    isObjTrue(process.env.NEXT_PUBLIC_FEATURE_BBC_GOOD_FOOD)
  ) {
    return <BBCGoodFoodHomepage voucherCode={bbcGoodFoodVoucher} />
  }

  return (
    <Homepage
      voucherCode={voucherCode}
      heroImageDesktop={heroImageDesktop}
      heroImageMobile={heroImageMobile}
      heroTrustpilotBannerDesktop={heroTrustpilotBannerDesktop}
      heroTrustpilotBannerMobile={heroTrustpilotBannerMobile}
      heroRecipeBoxImage={heroRecipeBoxImage}
    />
  )
}

export default BasePage

export const getStaticPaths = async () => {
  return {
    paths: basePages,
    fallback: true,
  }
}
export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(['cms-page-homepage'], () =>
    getCMSPage('page-homepage')
  )
  await queryClient.prefetchQuery(['cms-global-content'], getCMSGlobalContent)

  const { tomorrow, maxPastDate } = getImportantDates()

  await queryClient.prefetchQuery(['dateDeliverabilities'], () =>
    getDateDeliverabilities({
      minDate: tomorrow,
      maxDate: maxPastDate,
      postcode: MC_POSTCODE,
    })
  )

  const heroImageDesktop =
    'https://mindfulchef-images-production.imgix.net/homepage/StaticHeroDesktop.png'
  const heroImageMobile =
    'https://mindfulchef-images-production.imgix.net/homepage/StaticHeroImageMobile.png'
  const heroTrustpilotBannerDesktop =
    'https://mindfulchef-images-production.imgix.net/homepage/HeroTrustpilotBannerDesktop.png'
  const heroTrustpilotBannerMobile =
    'https://mindfulchef-images-production.imgix.net/homepage/HeroTrustpilotMobileBanner.png'
  const heroRecipeBoxImage =
    'https://mindfulchef-images-production.imgix.net/homepage/HeroRecipeBoxWithVegetables.png'

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      heroImageDesktop,
      heroImageMobile,
      heroTrustpilotBannerDesktop,
      heroTrustpilotBannerMobile,
      heroRecipeBoxImage,
    },
    revalidate: parseInt(process.env.NEXT_PUBLIC_CMS_REVALIDATE_INTERVAL, 10),
  }
}
